<template>
  <div class="notification-form">
    <v-form ref="form" class="notification-form__form" @submit.prevent="submit">
      <h3 class="py-5 primary--text">{{ header }}</h3>

      <span class="red--text"><strong>* </strong></span
      ><label class="text-field-label">Building</label>
      <SelectBuilding
        flat
        solo
        required
        class="mt-2"
        v-model="form.building_id"
        :pre-select="!isUpdate"
        :error-messages="form.$getError('building_id')"
        :loading="form.$busy"
        :disabled="form.$busy"
      />

      <span class="red--text"><strong>* </strong></span
      ><label class="text-field-label">Notification Body</label>
      <v-textarea
        flat
        solo
        required
        hide-details="auto"
        class="mt-2"
        placeholder="Notification"
        v-model="form.notification"
        :error-messages="form.$getError('notification')"
        :loading="form.$busy"
        :disabled="form.$busy"
      />
      <p>{{ remainingCharacters }} recommended characters remaining</p>

      <span class="red--text"><strong>* </strong></span
      ><label class="text-field-label">Expiry Date</label>
      <DatetimePicker
        v-model="form.expires_at"
        flat
        solo
        required
        class="mt-2 mb-4"
        placeholder="Expiry Date"
        hide-details="auto"
        :error-messages="form.$getError('expires_at')"
        :loading="form.$busy"
        :disabled="form.$busy"
      />

      <v-checkbox
        v-model="form.is_active"
        hide-details="auto"
        label="Set as active"
        class="mt-0"
      ></v-checkbox>

      <v-checkbox
        v-model="form.send_push_notif"
        hide-details="auto"
        label="Send push notification"
        class="mt-0"
      ></v-checkbox>

      <div class="mt-4">
        <v-btn
          v-if="hasPermissions"
          type="submit"
          color="primary"
          class="mr-4 px-6"
          height="40px"
          width="100%"
          :loading="form.$busy"
          >{{ buttonLabel }}</v-btn
        >
      </div>
    </v-form>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Notification Form
 * ==================================================================================
 **/
import { mapState, mapActions } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import DatetimePicker from '@/components/fields/DatetimePicker'
import SelectBuilding from '@/components/fields/SelectBuilding'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  components: {
    DatetimePicker,
    SelectBuilding,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  props: {
    notification: {
      type: Object,
      default: () => {
        return null
      },
    },
  },

  data() {
    return {
      form: new Form({
        building_id: '',
        notification: '',
        expires_at: '',
        is_active: false,
        send_push_notif: false,
      }),
    }
  },

  computed: {
    ...mapState({
      createdNotification: (state) => state.notification.notificationDetails,
      permissions: (state) => state.auth.permissions,
    }),

    header() {
      return this.isUpdate ? 'Notification Information' : 'Create Notification'
    },

    buttonLabel() {
      return this.isUpdate ? 'Update' : 'Create'
    },

    submitApi() {
      return this.isUpdate ? this.updateNotification : this.createNotification
    },

    isUpdate() {
      return !!this.notification
    },

    remainingCharacters() {
      const maxLength = 200 // Adjust this to match the maxlength attribute
      const currentLength = this.form.notification.length
      const remaining = maxLength - currentLength
      return remaining >= 0 ? remaining : 0
    },

    hasPermissions() {
      return validatePermissions(
        [PERMISSION.NOTIFICATIONS_UPDATE],
        this.permissions
      )
    },
  },

  watch: {
    notification(newValue, oldValue) {
      this.initForm()
    },
  },

  methods: {
    ...mapActions({
      createNotification: 'notification/createNotification',
      updateNotification: 'notification/updateNotification',
    }),

    initForm() {
      if (this.notification) {
        this.form.building_id = this.notification.building_id
        this.form.notification = this.notification.notification
        this.form.expires_at = this.notification.expires_at
        this.form.is_active = this.notification.is_active
        this.form.send_push_notif = this.notification.send_push_notif
      }
    },

    async submit() {
      if (this.form.$busy) return

      this.form.$busy = true
      this.form.$clearErrors()

      let forUpdate = this.isUpdate
      await this.submitApi(this.getFormData())
        .then(() => {
          if (forUpdate) {
            this.form.$busy = false
            this.showSnackbar('Notification details successfully updated!')
          } else {
            this.showSnackbar('Notification successfully created!')
            this.$router.push({
              name: 'notifications',
            })
          }
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    getFormData() {
      let form = this.form.$data()
      if (this.isUpdate) {
        form.id = this.notification.id
      }

      return form
    },
  },
}
</script>
<style lang="scss">
.notification-form {
  &__form {
    max-width: 400px;
  }

  @media (max-width: 768px) {
    &__form {
      max-width: 100%;
    }
  }
}
</style>
